<template>
  <slot />
</template>
<style></style>
<script setup>
import { useHead } from "@vueuse/head";

useHead({
  bodyAttrs: {
    class:
      "vertical-layout vertical-menu-modern blank-page navbar-floating footer-static",
  },
});
</script>
